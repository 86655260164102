/** @format */

export const formatoNumero = (numero) => {
  return numero
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

export const format = (value, pattern) => {
  let result = "";
  let i = -1;
  for (let letter of pattern) {
    i++;
    if (letter === "#") result += value[i];
    else result += letter;
    if (i >= value.length) break;
  }
  return result;
};

export const guardarEnLocalStorage = (valor) => {
  localStorage.setItem("session_chapin", JSON.stringify(valor));
};

export const obtenerDeLocalStorage = () => {
  if (localStorage.hasOwnProperty("session_chapin")) {
    return JSON.parse(localStorage.getItem("session_chapin"));
  }
  return null;
};
